.tos-nested-list ol {
	 list-style-type: none;
	 counter-reset: item;
	 margin: 0;
	 padding: 0;
}
 .tos-nested-list ol > li {
	 display: table;
	 counter-increment: item;
	 margin-bottom: 0.6em;
}
 .tos-nested-list ol > li::before {
	 content: counters(item, ".") ". ";
	 display: table-cell;
	 padding-right: 0.6em;
}
 .tos-nested-list ol > li ol > li {
	 margin: 0;
}
 .tos-nested-list ol > li ol > li::before {
	 content: counters(item, ".") " ";
}
 .list-lower-alpha {
	 list-style-type: lower-alpha !important;
}
 .list-style-roman {
	 list-style-type: lower-roman !important;
}
 .developer-tos strong {
	 font-weight: 500;
}
 