.page-404 {
	 min-height: 100vh;
	 margin: auto;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 padding: 10rem;
}
 @media screen and (max-width: 900px) {
	 .page-404 {
		 flex-direction: column;
	}
}
 @media screen and (max-width: 900px) {
	 .page-404 {
		 justify-content: space-evenly;
		 padding: 2rem;
	}
}
 .page-404__image {
	 text-align: center;
	 width: 50%;
}
 @media screen and (max-width: 900px) {
	 .page-404__image {
		 flex: 1;
	}
}
 .billboard {
	 display: flex;
	 flex-direction: column;
	 justify-content: center;
	 align-items: flex-start;
	 padding: 2rem;
	 color: #333;
	 flex: 45% 0;
}
 @media screen and (max-width: 900px) {
	 .billboard {
		 /* padding-left: 0;
		 padding: 1rem; */
		 padding: 1rem 1rem 1rem 0;
		 align-items: center;
	}
}
 @media screen and (max-width: 600px) {
	 .billboard {
		 padding: 1rem;
	}
}
 .billboard__heading {
	 font-size: 4rem;
	 font-weight: 300 !important;
	 margin-bottom: 2rem !important;
}
 .billboard__message {
	 margin-bottom: 2rem !important;
	 font-size: 1.8rem;
}
 .btn-set {
	 display: flex;
	 justify-content: space-between;
	 padding: 2rem 0;
}
 .btn-set__help {
	 margin-left: 2rem;
}
 .page-window {
	 border: 0.6rem solid #ccc;
	 border-top: none;
	 border-radius: 1rem;
	 display: flex;
	 flex-direction: column;
	 justify-content: space-between;
	 align-self: stretch;
}
 .page-window__actions {
	 display: flex;
	 background-color: #ccc;
	 padding: 0.75rem 0;
	 align-items: center;
	 justify-content: flex-start;
}
 .action__item--1 {
	 width: 1.4rem;
	 height: 1.4rem;
	 border-radius: 50%;
	 margin-left: 0.75rem;
	 background-color: #fff;
}
 